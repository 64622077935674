import { Injectable } from '@angular/core';
import { ApiService } from '../../core/services/api/api.service';
import { FindAllDoctorsQueryI } from './interfaces/find-all-doctors-query.interface';
import { DoctorI } from '../../core/shared/interfaces/entities/doctor.interface';
import { UpdateDoctorStatusRequestBody } from './interfaces/update-doctor-status-request-body.interface';

@Injectable({
  providedIn: 'root',
})
export class DoctorsApiService {
  constructor(private readonly apiService: ApiService) {}
  findAll(findAllDoctorsQuery: FindAllDoctorsQueryI) {
    return this.apiService.get<{
      medicalServiceProviders: DoctorI[];
      count: number;
    }>('medical-service-providers', findAllDoctorsQuery);
  }
  findOne(doctorID: string) {
    return this.apiService.get<DoctorI>(
      `medical-service-providers/${doctorID}`
    );
  }
  update(userID: string, accountStatus: number) {
    return this.apiService.patch<DoctorI>(
      `admins/change-account-status/${userID}`,
      { accountStatus }
    );
  }
  updateStatus(
    userID: string,
    updateDoctorStatusRequestBody: UpdateDoctorStatusRequestBody
  ) {
    return this.apiService.patch<DoctorI>(
      `medical-service-providers/change-application-status/${userID}`,
      updateDoctorStatusRequestBody
    );
  }
}
